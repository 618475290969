import React from "react"

// Comps
import Header from "../headers/Header"
import Panel from "../layout/Panel"

// Styles
import containerStyles from "./Container.module.styl"

const Container = props => {
    return (
        <div className={containerStyles.Container}>
            <Header location={props.location} />
            <Panel size="48">{props.children}</Panel>
        </div>
    )
}

export default Container
