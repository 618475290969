import React from "react"
import Link from "gatsby-link"

// Styles
import headerCircleStyles from "./HeaderCircle.module.styl"

const HeaderCircle = () => <i className={headerCircleStyles.HeaderCircle}></i>
const HeaderCircleGoBack = () => (
    <Link
        to="/"
        className={headerCircleStyles.HeaderCircleLink}
        title="Retour à l'accueil"
    >
        <i className={`${headerCircleStyles.HeaderCircle}`}></i>
    </Link>
)

export { HeaderCircle, HeaderCircleGoBack }
