import React from "react"
import { Helmet } from "react-helmet"

// MISC
import meta from "../../images/meta.png"

const Metas = props => {
    const image = props.image.length ? props.image : meta

    return (
        <Helmet
            htmlAttributes={{
                lang: "fr",
            }}
        >
            <title>{`Clément - Confinement${
                props.title.length ? " - " + props.title : ""
            }`}</title>

            <link rel="canonical" href={props.url} />
            <meta name="description" content={props.description} />
            <meta name="image" content={image} />

            <meta
                itemprop="name"
                content={`Clément - Confinement${
                    props.title.length ? " - " + props.title : ""
                }`}
            />
            <meta itemprop="description" content={props.description} />
            <meta itemprop="image" content={image} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:title"
                content={`Clément - Confinement${
                    props.title.length ? " - " + props.title : ""
                }`}
            />
            <meta name="twitter:description" content={props.description} />
            <meta name="twitter:site" content="@clmntclmnt" />
            <meta name="twitter:image" content={image} />

            <meta
                property="og:title"
                content={`Clément - Confinement${
                    props.title.length ? " - " + props.title : ""
                }`}
            />
            <meta property="og:description" content={props.description} />
            <meta property="og:url" content={props.url} />
            <meta
                property="og:site_name"
                content={`Clément - Confinement${
                    props.title.length ? " - " + props.title : ""
                }`}
            />
            <meta property="og:locale" content="fr_FR" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={image} />
        </Helmet>
    )
}

export default Metas
