import Helmet from "react-helmet"
import React from "react"
import { Link } from "gatsby"

// Comps
import { HeaderCircle, HeaderCircleGoBack } from "../misc/HeaderCircle"

// Layout
import { Row, Cell } from "../layout/Layout"

// Styles
import headerStyles from "./Header.module.styl"

// Libs
import * as moment from "moment"
import "moment/locale/fr"

class Header extends React.Component {
    constructor(props) {
        super(props)

        moment.locale("fr")

        this.onClickToggle = this.onClickToggle.bind(this)
    }

    state = {
        startDate: this.getDate(),
        theme: "light",
    }

    getDate() {
        const start = moment([2020, 2, 13])

        const days = Math.ceil(start.diff(moment(), "days", true))

        const hours = moment().format("HH")
        const minutes = moment().format("mm")
        const seconds = moment().format("ss")

        return `${Math.abs(days)}j ${hours}h ${minutes}m ${seconds}s`
    }

    getThemeFromLS() {
        const themeValue = window.localStorage.getItem("theme")
            ? window.localStorage.getItem("theme")
            : "light"

        return themeValue
    }

    componentDidMount() {
        this.setState({
            theme: this.getThemeFromLS(),
        })

        this.interval = setInterval(() => {
            this.setState({
                startDate: this.getDate(),
            })
        }, 500)
    }

    onClickToggle(e) {
        e.preventDefault()

        const oldState = this.getThemeFromLS()
        const newState = oldState === "light" ? "dark" : "light"

        this.setState({
            theme: newState,
        })

        window.localStorage.setItem("theme", newState)
    }

    render() {
        return (
            <>
                <Helmet
                    bodyAttributes={{
                        class: `theme-${this.state.theme}`,
                    }}
                />
                <header className={headerStyles.Header}>
                    <Row>
                        <Cell start="0" end="12">
                            <div className={headerStyles.Header__inner}>
                                <div className={headerStyles.Header__left}>
                                    {this.props.location.pathname.length ===
                                        1 && <HeaderCircle />}
                                    {this.props.location.pathname.length !==
                                        1 && <HeaderCircleGoBack />}
                                </div>
                                <div className={headerStyles.Header__center}>
                                    <div
                                        className={
                                            headerStyles.Header__center__top
                                        }
                                    >
                                        <Link
                                            to="/"
                                            className={`${headerStyles.Header__title} u-c-black heading-1`}
                                        >
                                            {this.props.location.pathname
                                                .length === 1 && (
                                                <h1>Confinement</h1>
                                            )}
                                            {this.props.location.pathname
                                                .length !== 1 && `Confinement`}
                                        </Link>
                                        <span
                                            className={`${headerStyles.Header__date} small u-c-white`}
                                            dangerouslySetInnerHTML={{
                                                __html: `${this.state.startDate}`,
                                            }}
                                        ></span>
                                    </div>
                                    <p
                                        className={`${headerStyles.Header__teasing} teasing-2`}
                                    >
                                        Je vais un peu écrire sur mon
                                        confinement.
                                    </p>
                                </div>
                                <div className={headerStyles.Header__right}>
                                    <button
                                        className={`${headerStyles.Header__toggle} btn--toggle`}
                                        onClick={this.onClickToggle}
                                    >
                                        <span
                                            className={
                                                headerStyles.Header__toggle__state
                                            }
                                        >
                                            {this.state.theme === "light"
                                                ? "Sombre"
                                                : "Clair"}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className={headerStyles.Header__right__mobile}>
                                <button
                                    className={`${headerStyles.Header__toggle} btn--toggle`}
                                    onClick={this.onClickToggle}
                                >
                                    <span
                                        className={
                                            headerStyles.Header__toggle__state
                                        }
                                    >
                                        {this.state.theme === "light"
                                            ? "Sombre"
                                            : "Clair"}
                                    </span>
                                </button>
                            </div>
                        </Cell>
                    </Row>
                </header>

                <Row>
                    <Cell start="0" end="12">
                        <hr className={headerStyles.Header__separator} />
                    </Cell>
                </Row>
            </>
        )
    }
}

export default Header
